// OpenReplayTracker.tsx
"use client";

import { useEffect } from "react";

export default function OpenReplayTracker() {
  if (
    typeof window !== "undefined" &&
    window.location.hostname !== "localhost"
  ) {
    useEffect(() => {
      // Import the tracker dynamically
      import("@openreplay/tracker").then(({ default: Tracker }) => {
        const tracker = new Tracker({
          projectKey: "gWS3ka98wPNtujy4I9nR",
        });
        tracker
          .start()
          .catch((error) =>
            console.log({ error }, "failed to start openreplay"),
          );
      });
    }, []);
  }

  return null; // This component does not render anything in the UI
}
